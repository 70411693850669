<template src="./fale-conosco.html">
</template>

<script>
import mixins from "@/mixins/mixins.js";
export default {
  mixins: [mixins],
  metaInfo: {
    title: "Filóo - Fale Conosco",
    htmlAttrs: {
      lang: "pt-BR",
    },
    meta: [
      { charset: "utf-8" },
    ],
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>

<style lang="scss" src="./fale-conosco.scss">
</style>
